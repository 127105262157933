<template>
  <div class="articles-page page">
    <div class="articles-page__title-container">
      <h1 class="articles-page__title">Статьи</h1>
    </div>
    <div class="articles-page__container">
      <div ref="articlesList" class="articles-page__content">
        <div v-if="showArticles" class="articles-page__items">
          <router-link
            :to="{ name: 'blog-id', params: { id: item.id } }"
            v-for="(item, i) in articles.data"
            :key="i"
            class="articles-page__item"
          >
            <ImgComponent class="articles-page__item-img" :head_img="item.head_img" />
            <div class="articles-page__item-info">
              <span v-if="item.date_end" class="articles-page__item-date">
                до {{ item.date_end | formatDate }}
              </span>
              <h3 v-if="item.title" class="articles-page__item-title">{{ item.title }}</h3>
            </div>
          </router-link>
        </div>
        <span v-else>Статьи отсутствуют</span>
        <PaginationComponent
          v-if="showPagination"
          :page="page"
          :total="Math.ceil(articles.paginatorInfo.total / first)"
          @change="paginate"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import ARTICLES_PAGE from "@/graphql/pages/ArticlesPage.graphql";

export default {
  name: "ArticlesPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: ARTICLES_PAGE,
        variables: {
          first: store.state.isMobile ? 6 : 10,
        },
      })
      .then(({ data }) => {
        store.state.articles_page.articles = data.articles_paginate;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  data() {
    return {
      page: 1,
      first: this.$store.state.isMobile ? 6 : 10,
      paginateLoading: false,
    };
  },
  computed: {
    articles() {
      return this.$store.state.articles_page.articles;
    },
    showArticles() {
      return this.articles && this.articles.data && this.articles.data.length;
    },
    showPagination() {
      return this.showArticles && Math.ceil(this.articles.paginatorInfo.total / this.first) > 1;
    },
  },
  methods: {
    async paginate(page) {
      if (!this.paginateLoading) {
        this.page = page;
        this.paginateLoading = true;
        await this.load();
        this.scrollUp();
      }
    },
    async load() {
      await this.$apollo.provider.defaultClient
        .query({
          query: ARTICLES_PAGE,
          variables: {
            page: this.page,
            first: this.first,
          },
        })
        .then(({ data }) => {
          this.paginateLoading = false;
          this.$store.state.articles_page.articles = data.articles_paginate;
          this.$store.state.categories = data.categories;
        })
        .catch(() => {
          this.filterLoading = false;
          this.paginateLoading = false;
        });
    },
    scrollUp() {
      if (window.scrollY > this.$refs.articlesList.offsetTop) {
        window.scrollTo({ top: this.$refs.articlesList.offsetTop });
      }
    },
  },
  components: {
    PaginationComponent,
  },
  metaInfo: {
    title: "Акции",
  },
};
</script>

<style lang="stylus">
.articles-page {
  width 100%
  display grid
  grid-gap 32px
  padding-top 32px
  padding-bottom 48px
  +below(420px) {
    grid-gap 16px
  }

  &__content {
    display grid
    grid-gap 32px
    width 100%
    max-width var(--main_width)
  }

  &__items {
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 24px
    +below(768px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    display grid
    grid-gap 16px
    padding 8px 8px 16px
    color var(--gray-900)

    &-img {
      width 100%
      height 290px
      object-fit cover
      object-position center
      border-radius: var(--main_radius);
    }

    &-info {
      display grid
      grid-gap 4px
    }

    &-date {
      font-weight: 400;
      font-size: 0.875em
      line-height: 20px;
      color: var(--gray-300)
    }

    &-title {
      font-style: normal;
      font-weight: 400;
      font-size: 1.5em
      line-height: 32px;
      margin 0
    }
  }

  &__container {
    display flex
    justify-content center
    padding: 0 15px;
  }

  &__title {
    max-width var(--main_width)
    width 100%
    font-weight: normal;
    font-size: 2.25em;
    line-height: 50px;
    display: flex;
    align-items: center;
    color: var(--body-color);
    margin 0 auto

    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
    }
  }
}
</style>
